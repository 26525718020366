import React from "react"
import Join from "../components/join"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../images/cereal.svg"

const CoursesPage = () => (
  <Layout>
    <Seo title="Health" />

    <header>
      <img
        src={Image}
        width={290}
        height={260}
        alt="restful coder logo"
      />
    </header>

    <br />
    <br />

    <section>
      <h2>Physical health</h2>

      <p>Do you find yourself sitting at your desk all day? How about eating lunch at your desk when you're trying to meet deadlines? And you're getting eight hours of sleep, right?</p>

      <p>Unfortunately, many of us answer yes to the above questions about common bad habits of programmers that are very unhealthy. They can lead to weight gain, bad posture, sore backs, carpal tunnel, and inability to think clearly.</p>

      <h2>Mental health</h2>

      <p>It's not just your physical health that you need to monitor. If you aren't getting enough sleep or are stressed for long periods of time, it will soon negatively affect your mental health. Also if you aren't managing your stress this can lead to physical problems and burnout.</p>

      <h2>Opportunity</h2>

      <p>If your physical and mental health are lacking, you're likely distracted and irritable. This can lead to missing out on little and big opportunities, effectively giving yourself bad luck.</p>
    </section>

    <Join />
  </Layout>
)

export default CoursesPage
